<template>
  <div>
    <!-- <upload-img v-model="img"></upload-img> -->
    <a-spin :spinning="false">
      <div class="bg-w pd20" style="min-height: 800px; min-width: 1000px">
        <div style="max-width: 800px">
          <div class="ft20 ftw500 cl-main">站点设置</div>
          <a-alert
            class="mt20"
            message="平台信息的设置,主要更改LOGO和版权信息"
            type="success"
          />
          <!-- 设置主题 -->
          <div class="set-them" style="margin-top: 10px">
            <span
              :class="['them-item', { 'c-active': !isDark }]"
              @click="isDark = false"
              >默认模式</span
            >
            <a-switch v-model:checked="isDark" />
            <span
              :class="['them-item', { 'c-active': isDark }]"
              @click="isDark = true"
              >黑夜模式</span
            >
          </div>
          <!-- 设置主题end -->
          <!-- <div style="margin: 10px 0;">
            <a class="menu-act" href="javascript:;" @click="showPwd = true">
              <i class="iconfont ft14 iconedit"></i>
              <span class="ml10">修改密码</span>
            </a>
          </div>
          <div>
            <a class="menu-act" href="javascript:;" @click="loginOut()">
              <i class="iconfont ft14 iconsubnav_shop04"></i>
              <span class="ml10">退出登录</span>
            </a>
          </div> -->

          <div v-if="datas != null" class="mt20">
            <a-form-model
              ref="ruleForm"
              :model="datas"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-form-model-item label="站点名称">
                <a-input v-model="datas.name" style="width: 300px" />
              </a-form-model-item>
              <a-form-model-item label="电话号码">
                <a-input v-model="datas.tel" style="width: 300px" />
              </a-form-model-item>
              <a-form-model-item label="站点域名">
                <a-input v-model="datas.domain" style="width: 300px" />
              </a-form-model-item>
              <!-- <a-form-model-item label="最大创建店铺数量">
                <a-input v-model="datas.limit_num" style="width: 100px" />个
                限制单个用户创建的店铺数量
              </a-form-model-item> -->
              <a-form-model-item label="ICP备案号">
                <a-input v-model="datas.icp" style="width: 300px" />
              </a-form-model-item>
              <!-- <a-form-model-item label="版权图片" help="小程序首页底部显示">
                <upload-img v-model="datas.copyright_img"></upload-img>
              </a-form-model-item> -->
              <a-form-model-item label="LOGO" help="商铺后台使用">
                <upload-img v-model="datas.logo_round"></upload-img>
              </a-form-model-item>
              <!-- <a-form-model-item label="用户后台登录" help="请使用白色透明的">
                <upload-img
                  v-model="datas.logo_white_account_login"
                ></upload-img>
              </a-form-model-item>
              <a-form-model-item
                label="用户后台应用管理"
                help="请使用白色透明的"
              >
                <upload-img
                  v-model="datas.logo_white_account_manage"
                ></upload-img>
              </a-form-model-item>

              <a-form-model-item label="总后台登录" help="请使用白色透明的">
                <upload-img v-model="datas.logo_white_admin_login"></upload-img>
              </a-form-model-item>
              <a-form-model-item label="总后台应用管理" help="请使用白色透明的">
                <upload-img
                  v-model="datas.logo_white_admin_manage"
                ></upload-img>
              </a-form-model-item> -->

              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="saveData"> 保存设置 </a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
    </a-spin>
    <a-modal v-model="showPwd" title="设置密码" on-ok="handleOk">
      <template slot="footer">
        <a-button key="back" @click="handlePwdCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="pwdloading"
          @click="handlePwdOk"
        >
          确认
        </a-button>
      </template>
      <a-form-model
        :model="pwd"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="输入密码">
          <a-input type="password" v-model="pwd.pwd" />
        </a-form-model-item>
        <a-form-model-item label="再次确认">
          <a-input type="password" v-model="pwd.repwd" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import uploadImg from "../../components/upload/upload.vue";
export default {
  components: {
    uploadImg,
  },
  data() {
    return {
      img: "",
      loading: false,
      datas: null,
      pwdloading: false,
      showPwd: false,
      pwd: {
        pwd: "",
        repwd: "",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    };
  },
  created() {
    this.getData();
  },
  computed: {
    isDark: {
      get() {
        return this.$store.state.them.isDark;
      },
      set(val) {
        this.$store.commit("them/setIsDark", val);
      },
    },
  },
  methods: {
    loginOut() {
      localStorage.removeItem("admin-token");
      this.$router.push("/");
    },
    handlePwdCancel() {
      this.showPwd = false;
    },
    handlePwdOk() {
      if (this.pwdloading == true) return;
      this.pwdloading = true;
      this.$http
        .api("platform/admin/editPwd", {
          pwd: this.pwd.pwd,
          repwd: this.pwd.repwd,
        })
        .then((res) => {
          this.pwdloading = false;
          this.$message.success("密码设置成功");
          this.showPwd = false;
        })
        .catch((res) => {
          this.pwdloading = false;
        });
    },
    getData() {
      this.loading = true;
      this.$http
        .api("platform/admin/getSettingData", {
          key: "site",
        })
        .then((res) => {
          this.loading = false;
          this.datas = res.datas;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    saveData() {
      this.loading = true;
      this.$http
        .api("platform/admin/saveSettingData", {
          key: "site",
          datas: JSON.stringify(this.datas),
        })
        .then((res) => {
          this.loading = false;
          this.$message.success("保存成功");
        })
        .catch((res) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.menu-act {
  color: #000000a6;
}
</style>
